#story-table-header {
    position: sticky;
    top: -16px;
    z-index: 1;
}

.table-story {
    .form-check-input {
        margin: 0 auto;
        position: relative;
    }
}

//responsive mobile
@media (max-width: 768px) {
    .table-story {
        th, tr {
            font-size: 0.8rem;
        }
    overflow: auto;
    }
}