@import '../../../styles/colors.scss';

.tab_content {
  padding: 1.5rem;
  border: 1px solid $background;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.tab_content>div {
  padding: 1rem;
  line-height:2rem ;
}

//responsive mobile
@media (max-width: 768px) {
  .tab_content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }
}

.disabledTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  width: 100%;
  height: fit-content;
  p {
    margin: 0 !important;
    opacity: 0.7;
  }
  .tabspin {
    position: absolute;
    z-index: 10;
  }
}