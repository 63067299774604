.group {
    width: 100%;
    min-height: 22rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 2em;
    gap: 1em;
    border-radius: 10px;
    
    .item-thumbnail {
        max-width: 12.5rem;
        min-width: 12.5rem;
    }
    img {
        max-width: 100%;
        border: 0px;
        padding: 5px;
    }
    .img-thumbnail {
        background-color: transparent ;
        border: 0px;
    }

    .lock-content {
        position: relative;
        z-index: 2;
        border: 1px solid black;
        width: 2em !important;
        height: 2em;
        text-align: center;
        padding: 0 !important;
        border-radius: 5px;
        font-size: 1.2em;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        margin-left: 10em;
    }
    .img-private {
        margin-top: -2.4em;
    }
}
// responsive 
@media all and (max-device-width: 480px) {
    .group {
        margin-top: 2rem;
        .item-thumbnail {
            max-width: 9rem;
            min-width: 9rem;
        }

        img {
            width: 100%;
            border: none;
        }      
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .group {
        margin-top: 2rem;
        img {
            width: 100%;
            border: none;
        }
    }
}