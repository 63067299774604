

#pedagogicModule-form{

  .form-title {
    font-weight: bold;
    font-size: 1.2rem;
  }
  
  .form-selection {
    margin-top: 15px;
    display: flex;
    justify-content: flex-start;
  }

  .video-card{
    margin-bottom: 30px;
    min-height: 150px;
    max-height: 150px;
    border-radius: 0;
    
  }

  .video-card .card-body{
    min-height: 2rem;
    padding: 0;
    text-align: center;
    padding: 0.5rem;
  }

  .videoCard .card-footer{
    display: none;
  }

  .video-card .card-img-top {
    border-radius: 0;
    max-height: 100px;
    object-fit: contain;
  }

  .img-thumbnail {
      width: 150px;
  }
}