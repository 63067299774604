.card_group_media {
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%;
    color: black;
    justify-content: center;
}
.professional-card {
    padding: 0 0.5em;
}
@media screen and (max-width: 767px) {
    .card_group_media {
        .col-6 {
           width: 100%; 
        }
        .pe-4 {
            padding-right: 0!important;
        }
    }
    .professional-card {
        margin: 2em 0;
    }
}