@import '../../styles/colors';

.header {
	position: relative;
	background-color: $background;
	height: 116px;
	color: white;
	min-width: 100%;
	
	.navbar-brand {
		cursor: pointer;
	}

	.burger-icon {
		color: whitesmoke;
		font-size: 44px;
	}
	.navbar-text {
		color: rgba(255, 255, 255, 0.7) !important;
	}

	.dropdown-menu{
		right: calc(0%)!important;
		left: inherit;
	}

	@media only screen and (max-width: 1200px) {
		margin-left: 0;
		margin-right: 0;
		width: 100% !important;
	
	}
}

.header:before {
	position: absolute;
	content: "";
	box-shadow: 0px 2px 7px 2px rgba(0, 0, 0, 0.42);
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
}

//responsive mobile
@media only screen and (max-width: 768px) {
	.header {
		position: sticky;
		top: 0;
		z-index: 10;
	}
	
}
