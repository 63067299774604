.transfer-section {
    width: 100%;
    padding: 0.5em 2em;
    border: 1px lightgrey solid;
    border-radius: 10px;
    position: relative;
}

.selectors-clientabo {
    width: 100%;
    display: flex;
    position: relative;


}

.buttons-changeClient {
    display: flex;
    gap: 1em;
    align-items: center;
    margin-bottom: 1.5em;

}