@import '../../styles/colors.scss';


.welcomeBanner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.6em 2em;
    background-color: white;
    color: black;
    z-index: 1000;
    position: relative;
    top: 0;
    text-align: center;
    font-weight: 600;
}

.welcomePreview {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.6em 2em;
    background-color: white;
    color: black;
    text-align: center;
}

.wbdanger {
    background-color: $color6 !important;
    color: white !important;
}

.wbwarning {
    background-color: $orange-background !important;
}

.wbinfo {
    background-color: $blue-light !important;
}

.welcomePreview p {
    margin: 0 !important;
}

.welcomeBanner p {
    margin: 0 !important;
}

.closeX {
    position: absolute;
    right: 0.8em;
    top: 0.2em;
    font-weight: 900;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 1.3em;
}

.closeX:hover {
    opacity: 0.8;
    transition: all 0.2s;
}

.extraButton {
    text-wrap: nowrap;
}