.mainsquare {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 4px 3px 0px rgba(0,0,0,0.15);
  padding: 3em;
  display: flex;
  flex-direction: column;
  align-items: left;

  .grouptitle {
    margin: 0 0 1em 1em !important;
    display: flex;
    flex-wrap: nowrap; 
    gap: 1em;
    width: 100%;

    .headercontainer {
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 1;
      padding: 0;
      display: flex;
      gap: 1em;
      align-items: center;
      flex-wrap: wrap;

      #titletext {
        font-weight: 900;
        border: none !important;
        width: fit-content !important;
        padding: 0 !important;
        font-size: 2em;
      }
    }
  }

  .editButtons {
    display: flex;
    margin: 0 1em 1em 1em;
    gap: 0.5em;
    .btn {
      width: fit-content;
      margin: 0 !important;
    }
  }




  .groupIcon {
    border: 1px solid black;
    width: 2em !important;
    text-align: center;
    padding: 0 !important;
    border-radius: 5px;
    font-size: 1.2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .headsetView {
    margin: 0 0 4em 0 !important;
    align-self: center;
    width: 100%;
  }

  .infoSection {
    display: flex;
    justify-content: center;

    .groupInfoCard {
      width: 80% !important;
      align-self: center;
      display: flex;
      gap: 2em;
      justify-content: center;


      .groupThumbnail {
        width: 18%;
        overflow: hidden;
        img {
          width: 100%;
        }
      }

      .groupInfos {
        width: fit-content;
        max-width: 50%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
      }
    }


  }

.interactiveInfoRow {
  margin-top: 2em;
  display: flex;
  justify-content: center;
  width: 100%;

  .alert {
    width: fit-content;
  }
}
  .parentRow {
    margin-top: 3em !important;
    display: flex;
    justify-content: center;
    width: 100%;

    .parentImages {
      display: flex;
      justify-content: center;
      gap: 1.5em;
      height: fit-content;
      padding: 0.3em;
      width: 100%;

      .parentImage {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-basis: 10%;        
        padding: 0 !important;

        img {
          width: 100%;
          z-index: 10;
          transition: all 0.5s;
          cursor: pointer;

          &:hover {
            transform: scale(1.2);
            transition: transform 0.5s;
          }
        }

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 0.5em;
          width: 1px;
          height: 2em;
          background: black;
          transform: translateX(-50%);
        }

        i {
          font-size: 1em;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 0.6em;
          z-index: 10;
        }

      }

    }
  }

  .moduleLink {
    display: inline-block;
  }

  .btnPedago {
    min-width: 20em;
    margin: 0 0.5em;
  }

}

.tableFiles {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;

  table {
    border-collapse: collapse;
    background-color: #fff;
    width: 90%;
    table-layout: fixed;
  }

  th.name {
    width: 80%;
  }

  td {
    padding: 0.3em;
    text-align: center;
    word-break: break-word;
  }

  

}
.group360 {
  width: 100%;
  height: 40em;
  border-radius: 10px;
  overflow: hidden;
  padding: 0 !important;
}

@media all and (max-device-width: 480px) {
  .group360 {
    margin-top: 2rem;

    .item-thumbnail {
      max-width: 9rem;
      min-width: 9rem;
    }

    img {
      width: 100%;
      border: none;
    }
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .group360 {
    margin-top: 2rem;

    img {
      width: 100%;
      border: none;
    }
  }
}