@import '../../../styles/colors';

.item_details {
    .click-media {
        cursor: pointer;
    }

    .click-media:hover {
        font-weight: bold;
        color: $pink;
    }
}