@import '../../../styles/colors';

.groupIcon {
    border: 1px solid black;
    width: 2em !important;
    text-align: center;
    padding: 0 !important;
    border-radius: 5px;
    font-size: 1.2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .videoStats {
    display: flex;
    font-weight: 700;
    color: white;
    margin: 0.5em;
    justify-content: space-between;
    width: fit-content;
    margin-left: auto;
    
    .videoStat {
        text-align: center;
        padding: 0.5em 1em;
        border: 2px solid white;
        background-color: $blue-medium;
        display: flex;
        gap: 0.8em;
        justify-content: center;
        align-items: center;
        font-size: 0.9em;
    }
    .videoStat.left {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px; 
    }
    .videoStat.right {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px; 
    }
}

.comment_m360 {
    font-family : "Courier New", Courier, monospace;
    overflow-wrap: break-word;
}

.rome-div {
    max-width: 40vw;
}
@media screen and (max-width: 992px) {
    #header-video {
        flex-direction: column-reverse;
    }
    .rome-div {
        max-width: none;
    }
}
@media screen and (max-width: 600px) {
    .video-col {
        flex-direction: column;
    }
}