.editable-image-container {
    width: auto!important;
    display: flex;
    align-items: flex-start;
}

.img-editable{
    max-width: 150px !important;
}

.edit-icon {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    padding: 5px;
    color: black;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-left: -1em;
}
