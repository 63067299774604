#story-whiteList-modal {
	width:100vw;
	max-width: 1500px !important;
}

#story-whiteList-modal .modal-body {
	max-height: 1100px  !important;
	overflow: auto;
}
.modal-footer .btn .fas {
	color: white;
}