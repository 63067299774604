.list-group-item {
    .item-thumbnail {
        width:100px;
    }
    .float-right {
        float:right
    }
    .btn {
        margin:10px;
    }
}