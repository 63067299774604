@import '../../styles/colors';


.tab-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top:1rem;
  }
  
  .tab-info-card {
    min-height: 28rem;
    margin-left: 1rem;
    margin-bottom: 3rem;
    padding: 2rem;
    width: 50%;
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028),
    0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042),
    0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
    background-color: #EDF1F2;
    border-radius: 2%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-size: 1em;

    .client-activity {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      margin: 1em;

      .list-group {
        margin-bottom: 0.8em;
        line-height: 1.8;
      }
    }
  }

  .tab-info-card.tab-info-card--noBackground-color {
    background-color: inherit;
    box-shadow: unset;
  
    .tab-info-card-header {
      margin: 1rem 0 0rem;
    }
  }

  .tab-info-card.tab-info-card--noMinHeight {
    min-height: inherit;
    padding-bottom: 1.5rem;
  }

  .tab-info-card-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0 3rem;

    .header-heading {

      .header-title {
        margin: 0;
        font-size: 1.2rem;
        font-weight: bold;
      }

      .header-desc {
        margin: 0.5rem 0;
        font-size: 1rem;
        font-weight: bold;
      }

    }
  }

.design-VRbg {
  h3 {
    text-align: center;
  }
}

.tab-info-card-btn {
  width: 15rem;
  text-align: center;
  font-size: 0.9rem;
  align-self: center;
}

.table-subscription {
  tr.active {
    font-weight: bold;
    // transform: scale(1);
    // transition: 0.3s;
    background-color: #71baff !important;
  }
}

.parent-list {
  a {
    margin-left: 1rem;
  }
  a:hover {
    font-weight: bold;
  }
}

.children-list {
 .children-list-container {
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
 }

 a {
   margin-left: 1rem;
 }

 a:hover {
   font-weight: bold;
   color: inherit;
 }
}

 @media only screen and (max-width: 768px) {
  .tab-info-container {
    flex-direction: column;
  }

  .tab-info-card {
    width: 100%;
    overflow-y: auto;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .tab-info-card-header {
    .header-heading {
      .header-title {
        font-size: 0.8rem;
      }

      .header-desc {
        font-size: 0.8rem;
      }

    }
  }

  .tab-info-card-btn {
    width: 12rem;
    font-size: 0.7rem;
  }
}