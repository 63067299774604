@import '../../../styles/colors.scss';

.token-card:hover {
    cursor: pointer;
    background-color: $red!important;
}

.customSelect {
    padding: 0.2 0.5rem;
    font-size: 1em;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height: 15em;
}