@import '../../styles/colors';

.treeWrapper {
    position: relative;
}

.rd3t-tree-container {
    height: 80vh !important;
    border: 1px solid #ced4da;
    margin: 2em !important;
    border-radius: 10px;
}

.foreignObjectclass {
    position: relative;
}

.nodeMenu {
    box-sizing: content-box;
    width: 80%;
    height: 8em;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1em;
    justify-content: center;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    padding : 2em 0;

    .nodeTitle {
        font-size: 1.1em;
        font-weight: 600;
    }
}

.nodeBox {

    box-sizing: content-box;
    width: 80%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1em;
    justify-content: center;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    padding-bottom: 2.2em;
    border: 1px solid rgba(0, 0, 0, 0.125);
    background-color: white;

    .nodeTitle {
        font-size: 1.1em;
        font-weight: 600;
    }

    .nodeType {
        color: grey;
        margin: 0;
    }

    .miniThumbnail {
        width: 100%;
        height: 10em;
        overflow: hidden;
        border-radius: 5px;
        border: 1px solid #eca2ff;
        
        img {
            width: 100%;
        }
    }

    .nodeInfos {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0.8em;
    }

    .nodeContents {
        display: flex;
        gap: 1.4em;
        margin: 0.5em 0;
        justify-content: center;
    }

    .dateBlock {
        color: #92d3ff;
        align-self: left;
        text-align: left;
        margin-left: 1em;
    }
}

.mini-icons {
    position: absolute;
    right: 11%;
    top: 0.5em;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 0.4em;
    font-size: 2em;
    width: 12%;

    .mini-icon {
        color: $background;
        background-color: white;
        border: solid 2px white;
        border-radius: 6px;
        padding: 0.2em;
        box-shadow: 3px 2px 4px rgba(0, 0, 0, 0.4);
        cursor: auto;

    }
}

.dashedLine {
    border-left: 3px dashed black;
    height: 100px;
    margin-left: 11.25em;
    z-index: -1;
}

.expandBtn {
    font-size: 3em !important;
    font-weight: normal !important;
    width: 1.5em;
    height: 1.5em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 3em;
    background-color: rgba(255, 255, 255);
    border-radius: 50%;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.08);
    &:hover {
        font-weight: 900 !important;
        border-radius: 2em;
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.3);
    }
}

.downButton {
    background-color: $pink;
    color: white;
}

.globalBTN {
    font-size: 2rem;
        position: absolute;
    width: fit-content !important;
    right: 0;
    top: 2em;
}

.foundGroup {
    border: 6px solid green;
}

.privateGroup {
    background-color: #e9ecef;
}