@import '../../styles/colors.scss';

.logs-shadow {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 24;
}
.logs-window {
    position: fixed;
    top: 0;
    right: 0;
    padding: 2em 4em;
    width: 50%;
    height: 100%;
    background-color: white;
    z-index: 25;
    overflow-y: scroll;

    .tab-content {
        height: 80%;
    }

    ._metiers360-loader-widget {
        height: 70vh;
    }
}

.tag {
    padding: 0.2em 0.5em;
    background-color: $background-grey;
    border: solid 1px $text-grey;
    border-radius: 5px;
}
.tag-action-create, .tag-response-code-2 {
    background-color: $green-light;
    border-color: $green-medium;
}
.tag-action-update, .tag-response-code-4 {
    background-color: $orange-background;
    border-color: $orange;
}
.tag-action-delete, .tag-response-code-5 {
    background-color: $red-background;
    border-color: $red;
}
.tag-response-code-1 {
    background-color: #faf882;
    border-color: #ffe711;
}
.tag-response-code-3 {
    background-color: #cfadff;
    border-color: #791cf8;
}

.log-grey {
    color: $text-grey;
}

.log-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    border-left: 1.5px solid $grey-axonaut;
    margin-left: 0.3em;
    padding: 1em;
}

pre {
    background: #eee;
    padding: 1rem;
    border-radius: 3px;
    max-height: 50em;
    max-width: 42vw;
    margin-bottom: 0!important;
}
pre code {
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
}