@import '../../../styles/colors';
.select-multiple-client-widget {
    display: flex;
    justify-content: center;
    gap: 2em;
    margin: 1em;
    width: 100%;
    border : 1px solid lightgrey;
    padding: 3em;
    border-radius: 5px;
    height: 30em;

    .titre {
        align-self: baseline;
    }
    
    .badge-listed {
        background-color: green !important; 
    }

    .badge-fixed {
        background-color: grey !important; 
    }

    .client-badge:not(.badge-fixed):hover {
        background-color: red !important;
        cursor: pointer;
    }

    .client-badge.badge-fixed:hover {
        background-color: darkgrey !important;
    }

    .left {
        width: 68%;
        display: flex;
        flex-direction: column;
        gap: 0.8em;
        align-items: center;

        .search-client {
            width: 100%;
            flex: 0 1 auto;
        }

        .searchblock {
            position: relative;
            display: flex;
            gap: 1em;
            width: 100%;
            background-color: white;
            border: 1px solid darkgrey;
            border-radius: 5px;
            flex: 1 1 auto;
            height: 50%;
        }

    }

    .right {
        width: 28%;
        display: flex;
        flex-direction: column;
        gap: 1em;
        height: 100%;

        .selected-list {
            background-color: white;
            border-radius: 5px;
            border: 1px solid darkgrey;
            padding: 2em;
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            gap: 0.2em;
            overflow-y: scroll;
            overflow-x: hidden;
            flex: 1 1 auto;
        }

    }

    .parents-tags {
        display: flex;
        align-items: baseline;
        flex: 0 1 auto;

        .select-box {

            width: 50%;
            display: flex;
            justify-content: start;
            flex-direction: column;
            margin: 0 1em;

            .parent-tag-list {

                display: flex;
                align-items: center;

                .select-input {
                    width: 100%;
                    height: 2.5em;

                }

                .label {
                    width: max-content;
                    margin: 0.5em;
                }

            }

        }

    }

    .two-buttons-side {
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 2em;
    }

}

.loader-tags {
    width: auto;

    ._metiers360-loader-widget {
        width: auto;
        margin: 0;

        .child {
            margin: 0;
        }
    }
}
.all-disabled {
    background-color: $background-grey !important;
}