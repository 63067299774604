@import '../../styles/colors';

.graph-container {
    background-color: white;
    padding: 1em 0;
}
.audience-select-videos {
    border: solid 2px $background;
    border-radius: 8px;
    z-index: 1;
    position: relative;
    background: white;

    .contents_details_body {
        margin-bottom: 2em;
        overflow: hidden;

        .card_group_media {
            max-height: 58vh;
            overflow-y: auto;
        }
    }
}
.contents_details_card {
    background-color: white;
    max-height: 85vh;
}
.expand-btn {
    z-index: 2;
  position: sticky;
  display: flex;
  width: 100%;

  i {
    width: 2.5em;
    height: 2.5em;
    display: flex;
    background: white;
    border-radius: 2em;
    text-align: center;
    margin: auto;
    margin-top: -1em;
    padding: 0.8em;
    border: solid 2px;
  }
}
.hr-dates-filter, .hr-type-selector-filter {
    width: 16vw;
}
.hr-dates-filter {
    margin: 0 1em;
}
.hr-left {
    margin: 0 12em 0 1em;
}
.hr-right {
    margin: 0 1em 0 12em;
}
@media screen and (max-width: 1600px) {
    .hr-dates-filter, .hr-type-selector-filter {
        width: 12vw; 
    }
}
@media screen and (max-width: 1560px) {
    .hr-dates-filter, .hr-type-selector-filter {
        width: 10vw; 
    }
}
@media screen and (max-width: 1500px) {
    .hr-dates-filter, .hr-type-selector-filter {
        width: 7vw; 
    }
}
@media screen and (max-width: 1400px) {
    .hr-dates-filter, .hr-type-selector-filter {
        width: 5vw; 
    }
}
@media screen and (max-width: 1300px) {
    .hr-dates-filter, .hr-type-selector-filter {
        width: 0vw; 
    }
}