@import "../../styles/colors";

@media only screen and (max-width: 1255px) {
    .welcome {
        h1 {
            font-size: 1.8rem;;
        }

        h2 {
            font-size: 1.5rem;;
        }
    }
}

@media only screen and (max-width: 962px) and (min-width: 501px) {
    .welcome {
        h1 {
            font-size: 1.4rem;;
        }

        h2 {
            font-size: 1.2rem;;
        }
    }
}

@media only screen and (max-width: 500px) and (min-width: 320px) {

        .welcome {
            h1 {
                font-size: 1rem;
            }
    
            h2 {
                font-size: 0.8rem;
            }
        }
}
 