.table thead th {
    vertical-align: middle;
}

.wlList {

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.4em;
    font-size: 0.8em;

    .whitelistElement {
        min-width: 3em;
        color: black;
        padding: 0.3em;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.3em;
        border-radius: 5px;
        font-weight: 600;
        border: 1px solid black;
        background-color: white;
    }

    .blackList {
        background-color: black;
        color: white;
    }
    
    .subscriptionswL {
        background-color: red;
        color: white;
    }

    .restrictiveWL {
        background-color: #eca2ff;
        color: white;
    }

}
.contactM360:hover {
    font-weight: bold;
}