@import '../../styles/colors';

#sidemenu-wrapper {
	background-color: $background;
	min-height: 100vh;
	max-height: 100vh;
	position: fixed;
	z-index: 20;
	top: 0;
	left: 0;
	overflow-x: auto;
	-ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

}

#sidemenu-wrapper::-webkit-scrollbar {
	display: none;
}

.col-fixed {
	width: 250px !important;

	@media only screen and (max-width: 1200px) {
		top: 116px;
		bottom: 0;
		left: 0;
		width: 300px;
		z-index: 13;
	}

	.logo-container {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	#logo {
		display: block;
		width: auto;
		max-height: 116px !important;
	}

	.nav {
		width: 100%;

		&-wrapper {
			color: rgba(255, 255, 255, 0.7);

			width: 100%;
			padding: 0 0.5rem;
			a {
				text-decoration: none;
			}

			a:hover {
				text-decoration: none;
			}

			.separator {
				list-style-type: none;
				font-size: 1rem;
				font-weight: bold;
				padding: 1rem 0.5rem;
			}
			.nav-item {
				list-style-type: none;

				color: rgba(255, 255, 255, 0.7);
				transition: font-size 0.3s;

				font-size: 0.9rem;
				display: flex;
				align-items: center;
				padding: 0.5rem;
				.nav-icon {
					font-size: 20px;
					margin-right: 16px;
				}
			}

			.nav-item:hover {
				background-color: rgba(255, 255, 255, 0.1);
				color: white;
				border-radius: 5px;
				font-size: 1rem;
				transition: font-size 0.3s;
				box-shadow: -4px 4px 3px -1px rgba(0, 0, 0, 0.4);
				cursor: pointer;
			}
			.current-link .nav-item {
				background-color: rgba(255, 255, 255, 0.1) !important;
				color: white;
				border-radius: 5px;
			}
			.nav-link {
				color: white;
				padding:0;
			}
		}
	}
}
