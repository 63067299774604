.users  {
    display: flex;
    align-items: center;
    margin-top:4rem !important;
    width: 100%;
    text-align: center;
}

.users-table-container{
  overflow-x: scroll!important;
}

.item_details_btn_actions_sessions  {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:1rem !important;
  width: 100%;
}

.item_details_btn_actions_sessions > button{
    margin: 1rem;
}

.actionButtons>div {
    text-align: center;
    margin-top:2rem !important;
}

.item_details-thumbnail-session {
    width: 100%;
    margin: auto;
}
.arrow-accordion{
    margin-right: 1rem;
}


//responsive mobile
@media (max-width: 768px) {
    .tab_content {
      padding: 0.5rem;
    }

    .users  {
      overflow-x: scroll;
    }
  }
  
