@import '../../styles/colors.scss';

.webapp-container-instructions {
    font-size: 1.2rem;

    .code {
        font-weight: bold;
        color: $background;
    }
}

@media screen and (max-width: 768px) {
    .webapp-container-instructions {
      font-size: 0.8rem;
      margin-left: 0;
    }
}
  