@import "../../styles/colors.scss";

.round {
    height: 7px;
    width: 7px;
    display: inline-block;
    margin-bottom: 5px;
    margin-left: -3px;
    border-radius: 7px;
}
.round-green {
    background-color: $green-medium;
}
.round-red {
    background-color: $red;
}