@import "../../../../styles/colors.scss";

.answer-list-container {
    display: flex;
    flex-direction: row;
    min-height: 250px;
    .answer-list {
        flex:1;
        border-right: 2px solid black;
        padding: 1rem;

        .answer-element--active  {
            border: 2px solid #104D88;
            transform: scale(1.05);
           
        }
        
        .answer-element {
            padding: 5px;
            margin-bottom: 10px;
            box-shadow:  0 4px 4px #b1b1b1,
            0px 0px 5px #b1b1b1;
            border-radius: 15px;
            text-align: center;

            .correct-answer {
              display: flex;
              justify-content: center;
          
              i {
                font-size: 1.5rem;
                color: green;
              }
            }
        
            .answer-actions {
                display: flex;
                justify-content: space-around;

                i {
                    font-size: 1.5rem;
                    color:#104D88;

                    &:hover {
                        cursor: pointer;
                        background-color: white;
                        transform: scale(1.1);
                    }
                }

                .fa-trash {
                    opacity: 0.4;
                    color: inherit;
                    &:hover {
                      cursor: not-allowed;
                      opacity: 1;
                      color: red;
                    }
                  }
            }
        }
    }

    .answer-form-container {
        flex:2;
        padding: 1rem;

        .answer-form-header {
            display:flex;
            align-items: center;
            justify-content: space-between;

            i {
                font-size: 1.8rem;
                color: #104D88;
                &:hover {
                    cursor: pointer;
                    background-color: white;
                    transform: scale(1.1);
                  }
            }

        }
    }
}