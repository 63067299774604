@import '../../../styles/colors.scss';

.card-interaction {
    border-radius: 20px;
    width: 100%;
    min-height: 170px;
    margin-bottom: 4em;
    padding: 2em 2em 0 2em;
    background-color: #fff;
}
.axonaut-logo-interaction {
    width: 44px;
    height: 40px;
    padding: 0.5em 0.8em;
    background-color: $blue-axonaut;
    border-radius: 20px 0px 0px 20px;
}
.axonaut-card {
    border-radius: 0 20px 20px 20px;
    margin-right: 35px;
}
.non-axonaut-card {
    margin-left: 44px;
}
.title-interaction {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.5em;
    word-break:break-all;
}
.todo-interaction, .todo-interaction-disable {
    border-radius: 30px;
    border: 1px dashed $background;
    background: $blue-light;
    width: 7.5em;
    height: fit-content;
    padding: 0.2em 2em;
}
.todo-interaction:after, .todo-interaction-disable:after {
    content: 'À faire';
}
.todo-interaction:hover {
    border-color: $green-medium;
    background: $green-medium;
    color: #fff;
    
    &:after {
        content: '✓ Fait';
    }
}
.todo-interaction-disable {
    border: 1px dashed $text-grey;
    background: $background-grey;
}
.contacts-interaction, .date-interaction  {
    font-size: 14px;
    font-weight: 400;
    color: $text-grey;
}
.contacts-interaction {
    margin-bottom: 0.5em;

    span {
        font-style: italic;
    }
}
.footer-interaction {
    display: flex;
    align-items: center;
    margin: 3em -2.9em 0 0!important;
}
.type-interaction {
    display: flex;
    align-items: center;
    background-image: linear-gradient(134.8deg, #fff 35px, $background 0); 
    color: #fff;
    padding: 1em 2em 1em 4em;
    width: fit-content;
    border-radius: 0px 0px 20px 0px;

    i {
        font-size: 20px;
    }

    p {
        margin-bottom: 0;
        margin-left: 1em;
        font-size: 14px;
        font-weight: 500;
    }
}
.contactsM360-interaction {
    display: flex;
    margin: -3.5em 0 1em 0;
}
.contactM360-bubble {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $background;
    color: #fff;
    border-radius: 30px;
    width: 2em;
    height: 2em;
    font-size: 18px;
    font-weight: 700;
    margin-right: 0.5em;
}
.btn-edit-interaction,
.btn-delete-interaction {
    width: 35px;
    height: 35px;
    border-radius: 35px;
    padding: 0.35em 0.6em;
    font-size: 16px;
    color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); 
    margin-left: 0.8em;
    margin-bottom: 0.8em;
    cursor: pointer;
}
.btn-edit-interaction {
    background-color: $text-grey;

    &:hover {
        background-color: $background-grey;
        color: $text-grey;
    }
}
.btn-delete-interaction {
    background-color: $red;

    &:hover {
        background-color: $background-grey;
        color: $red;
    }
}
.content-interaction *:hover {
    background-color: transparent!important;
}
.icon-interaction-flow-1::before {
    color: $blue-light;
}
.icon-interaction-flow-2::before {
    color: $green-medium;
}