@import '../../../../styles/colors.scss';

// Variables

// Screen size
$verysmall: 550px;
$medium: 900px;


// Container with the main information when several cards are aside

.col-maininfo {
    margin: 0 2em;
    white-space:pre-wrap ;

    @media screen and (max-width: $verysmall) {
        xs: 12;
    }
    @media screen and (max-width: 900px)
     {
        margin: 1em 1em;
    }

}

// Gives upper and lower space to a row

.row-midspace {
    margin-bottom: 2em;
    margin-top: 2em !important;
}

.row-smallspace {
    white-space:pre-wrap ;
    margin-bottom: 1em;
    margin-top: 1em !important;
}

// All centered row

.row-allcenter {
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 1em 0 !important;
}

// Gives margin to a col {

.col-midspace {
    @media screen and (max-width: 900px) {
        margin: 1em;
    }
}

.col-allcenter {
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 2em;
    align-items: center;
}


// Comments card

.display-comments {
    width: 50%;

    @media screen and (max-width: $medium) {
        width: 90%;
    }
}

// Informative cards

.warning-info {
    width: 50%;

    @media screen and (max-width: $medium) {
        width: 70%;
    }
}

.btn-larger {
    padding: 0.65em 2em!important;
}
.left-button {
    margin: 0.4em 0;
    width: fit-content;
    font-size: 1em;
}
.axonaut-btn {
    background-color: $blue-axonaut!important;
    border-color: $blue-axonaut!important;

    &:hover {
        background-color: transparent!important;
        color: $blue-axonaut!important;
    }
}
.target-axonaut, .left-button a {
    text-decoration: none!important;
}

.miniedit {
    padding: 0.3em !important;
    display: inline-block;
    margin-left: 0.5em;
}

h5 {
    font-size: 1.2em;
}

td {

}