@import '../../styles/colors.scss';

#cgu-header {
    background-color: $background;
    height: 116px;
    color: white;
    min-width: 100%;
}
#cgu-body {
    margin: 4em 15vw;
}
p {
    font-size: 15px;
}
h2 {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 3px solid rgba(204, 204, 204, 0.8);
}
h4 {
    margin-top: 2em;
}
#logo {
    display: block;
    width: auto;
    max-height: 116px !important;
}