.container-formation{
    align-items: stretch !important;
    margin-top: 4rem  !important;
    max-width: 90% !important;
    min-width: 0% !important; 
 }
.item_details-thumbnail {
        max-width: 15rem !important;
}
.list-group-item, .list-group{
border: none !important;
box-shadow: none !important;
}
.list-sessions, .main-infos {
        max-width: 100% !important;
        min-width: 0% !important;
        margin-top: 2rem !important; 
}
.list-sessions-title-row {
        margin-top: 2rem;
        margin-bottom: 2rem;
        text-align: center;
}


@media (min-width: 481px) and (max-width: 768px) {
        .item_details {
          .item_details-card {
              margin-top: 10rem!important;
            }
        }
}
.description {
  margin: 1.5rem!important;
  padding: 1rem!important;
}