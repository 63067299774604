@import '../../../styles/colors.scss';

#table-header {
    position: sticky;
    top: 0px;
    z-index: 1;
}
.more {
    font-size: 0.85rem;
    cursor: pointer;
    word-spacing: 1rem;
}
.infinite-table {
    ._metiers360-loader-widget {
        margin: 0;

        .child {
            margin: 0;
        }
    }
}
.btn-options-table, .btn-options-table-open {
    background-color: white!important;
    color: $text-grey!important;
    font-weight: normal!important;
    font-size: 13px!important;
    border: 1px solid $background-medium-grey!important;
    border-radius: 5px 5px 0 0!important;
    border-bottom: none!important;
    padding: 0.5em 2em!important;

    &:hover {
        color: black!important;
    }
}
.btn-options-table-open {
    margin-bottom: -0.1em;
    z-index: 1;
}
.options-table-content {
    background-color: white;
    width: 100%;
    padding: 1em;
    border: 1px solid $background-medium-grey;
    border-bottom: none;

    .form-check-label {
        font-weight: normal;
    }
}
