@import "../../../../styles/colors";

.questions-container {
    display: flex;
    flex-direction: column;

    .question-element {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        margin: 0.5rem 0;
        padding: 1rem;
        box-shadow:  0 10px 15px #b1b1b1,
        0px -2px 5px #b1b1b1;
        background-color: white;
        border-radius: 15px;
        
        .question-actions {
            display: flex;
            justify-content: space-around;
            & > * {
                margin-left: 1rem;
            }

            i {
              font-size: 2rem;
              &:hover {
                cursor: pointer;
                color: $pink;
              }
            }
        
            .fa-trash {
              opacity: 0.4;
        
              &:hover {
                cursor: not-allowed;
                opacity: 1;
                color: red;
              }
            }
        }
    }


}