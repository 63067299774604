.background-img {
  background: url("../../images/background_front.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
}

.form-dispo {
  display: flex;
  justify-content: flex-end;
}

.form-dispo-content {
  padding: 3rem;
  margin-top: 3rem;
  flex-grow: 1;
}

.card {
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
  border-radius: 0.3rem;
  border:0;
    .card-header {
      text-align: center;
    }
    .card-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.7rem;
    }
}

.layout-footer {
  display: flex;
  background-color: #f8f9fa;
  font-size: 0.7rem;
  width: 100%;
}

@media (max-width: 601px) {
  .layout-footer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
  }
}

@media screen and (min-width: 100px) and (max-width: 1000px) {
  .background-img{
    display: flex;
    justify-content: center;
    align-items: center;
  }
} 

#layoutAuthentication {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-height: 100vh;
}

#layoutAuthentication #layoutAuthentication_content {
  min-width: 0;
  -webkit-box-flex: 1;
  flex-grow: 1;
}

#layoutAuthentication #layoutAuthentication_footer {
  min-width: 0;
}