@import '../../../styles/colors';

.contents_details_card {
    background-color: $background-grey!important;
    border: none!important;
    margin-top: 1.5rem;
    max-height: none!important;

    .contents_details_title {
        margin-left: 1em;
        padding: 1rem 0;
        font-size: 1.5rem;
    }

    .contents_details_body {
        max-width: 100%;
        padding: 0;

        .related_content_details {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 0.8rem;
            margin-bottom: 0.8rem;
            max-width: 100%;
            
            .related_content_details_item {
                padding: 0 0.5rem;
                width: 100%;
                height: 100%;

                .related_content_details_item-title {
                    color: #fff;
                    font-size: 1.5rem;
                    font-weight: bold;
                    margin-top: 1rem;
                    margin-left: 2rem;
                    padding: 0.5rem;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .contents_details_card {
        .contents_details_title {
            font-size: 1.2rem;
        }
    }

    .related_content_details {
        flex-direction: column;
        
        .related_content_details_item {
            padding: 1rem;
            min-width: 100%;
        }
    }
    .expand-icon {
        display: none;
    }
}

@media screen and (min-width: 768px) and (max-width: 1475px) {
    .related_content_details {
        flex-direction: row;
       
        .related_content_details_item {
            padding: 1rem;
        }
    }
    .expand-icon {
        display: none;
    }
}