@import '../../../../../styles/colors';

.imgQuiz{
    height: 80vh;
    align-items: center;
    background-size: 100%;
}
.intro {
    background-color: $background;
    border-radius: 10px;
    border:#ECA2FF solid 4px ;
    position: relative;
    top: 4%;
    left: 20%;
    width:60%; 
    height:92%;

    .textIntro {
        color: aliceblue;
        text-align: center;
        position: relative;
        top: 30%;
        padding: 2%;
        align-items: center;
        font-size: x-large;
    }

    .containerStartButton{
        text-align: center;
        transform: translateY(45%);
    }
    
    @media (min-width:100px) and (max-width:415px){
    
        .textIntro{
            top: 30%;
            padding: 2%;
            font-size: large;
        }
    }

    @media (min-width: 281px) and (max-width:415px){
                
        .textIntro{
                top: 30%;
                padding: 2%;     
            }
    }

}

@media(width: 280px){

    .intro, .question{
        // position: absolute;
        left: 0%;
        width: 100%;
        bottom: 5%;
        
        .textIntro{
            top: 30%;
            padding: 2%;
        }
    }



}
