.little-square {
    width: 6em;
    height: 2em;
}
.caption-number {
    margin-left: -1em;
}
.captions-color {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 3em;
}