.graph-container {
    background-color: white;
    padding: 1em 0;
}

#video-details-tab {
    ._metiers360-loader-widget {
        width: auto!important;
        min-height: 40vh;
    }
}