@import '../../styles/colors';

#main-wrapper {
    max-width: calc(100vw - 250px);
    max-height: 100%;
    margin-left: 250px;
    padding: 1rem;
    min-height: 100vh;
    margin-bottom: 6em; // To avoid help btn to be on top of other elements 

    .container-fluid {
        margin-top: 1.5rem;

        .table th, .table td {
            vertical-align: middle;
            max-width: 12rem;
            word-wrap: break-word;
        }
    }

    h2 {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 3px solid #cccc;
    }

    .form-control{
        margin-left: 0.5rem;
    }


    /** CSS details pages */

.item_details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 60rem ;

    .item_details-card {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
        margin-top: 12rem;
        padding: 0;
        position: relative;
        min-width: 48rem;
    
        .item_details-thumbnail {
            width: 35%;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -95%);
            
        }

        .item_details-body {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 0;
  
            .list-group {
                margin : 1.5rem;
                padding: 1rem;
                width: 80%;
                align-self: center;
                border: 1px solid #e5e5e5;
                box-shadow: 0 0.8rem 1rem rgba(0, 0, 0, 0.1);
  
                .list-group-item {
                    border: none;
                    font-size: 0.9rem;
                    margin: 0;
                    padding: 0.5rem ;
                }
  
                a.list-group-item:hover {
                    color: $pink;
                    font-weight: bold;
                    text-decoration: none;
                }
            }
  
            .item_details_btn_actions {
                font-size: 1rem;
                flex-direction: row;
                align-self: flex-end;
                margin: 2rem;
                position: relative;
                z-index: 8;
            }
  
            .item_details_btn_actions > .btn-secondary, .item_details_btn_actions > .btn-danger {
                margin-right: 1rem;
            }
  
            .item_details_card_title {
                font-size: 2.5rem;
                font-weight: bold;
                // margin-bottom: 1rem;
                margin-top: 2rem;
                align-self: center;
                text-align: center;
                padding-top: 1rem;
                word-break: break-word;
            }
  
            .item_details_card_subtitle {
                font-size: 1.5rem;
                margin-top: 0rem;
                align-self: center;
                text-align: center;
                padding: 0rem;
                word-break: break-word;
            }
  
            .associated_card {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin:0 auto;
                padding: 0;
                width: 100%;
  
                .associated_card_item {
                    background: linear-gradient(to top right, $pink, $background);
                    margin: 1rem;
                    padding: 1rem;
                    border: 1px solid #e5e5e5;
                    box-shadow: 0 0.8rem 1rem rgba(0, 0, 0, 0.1);
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
  
                    .associated_card_title {
                      color: #fff;
                      font-size: 2rem;
                      font-weight: bold;
                      margin-top: 2rem;
                      text-align: center;
                  }
                }
            }
  
            .item_details_description {
                font-size: 0.9rem;
                padding: 2rem 2rem 0 2rem;
                margin: 2rem 2rem 0 2rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
  
                .item_details_description_title {
                    font-size: 1.5rem;
                    font-weight: bold;
                    margin-bottom: 1rem;
                    color: #aaa4a4;
                }
            }
        }
    }
  }

  .item-page {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .btn-group {
        margin: 0;
        padding: 0;
    }

    .btn-primary {
        margin: 0;
    }
}

    .thumbnail_header_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        margin: 0;
        padding: 0;
    
        .card-img {
        top:0;
        left:0;
        width:100%;
        height:40rem;
        object-fit: cover;
        z-index: -100;
        position: absolute;
        filter: blur(5px);
        }

        .card-title {
            font-size: 3rem;
            font-weight: bold;
            margin: 0 auto;
            margin-top: 5rem;
        }
    }

  .side-filter-element{
    background-color: #E9ECEF;
    color: $background;
    padding: 1rem;
    margin-bottom: 1rem;
    margin-left: 0.1rem;
    font-size: 0.9rem;
    border-radius: 5px;
    .side-filter-element-title{
        text-align: center;
        font-weight: bold;
        margin-bottom: 1rem !important;
        width: 90%;
        margin: auto;
        border-bottom: 0.2rem solid $background ;
    }
    .form-check{
        margin-bottom: 0.5rem;
    }
    .form-check-label{
        margin-left: 0.5rem;
    }
    .selected-filter{
        color: #45ABFF;
        font-weight: bold;
    }
  }

   /** CSS reponsive details pages */
  
  @media all and (max-device-width: 480px) {
    .item_details {
        .item_details-card {
            margin-top: 0;
            max-width: 20rem;
            min-width: 18rem;
  
            .item_details-body {
                .list-group {
                    bottom:3rem;
                    margin:0;
                    padding: 0.5rem;
                   
                    a{
                        font-size:0.7rem;
                    }
  
                    .list-group-item {
                        font-size: 0.85rem;
                    }
                }
                .item_details_card_title {
                    font-size: 1.3rem;
                    margin-bottom: 1rem;
                }
                .item_details_btn_actions {
                    margin: 1rem 0.5rem;
                }
                .item_details_btn_actions > .btn-primary:nth-child(2) {
                    margin-right: 0;
                }
                .item_details_description {
                    padding: 0.8rem;
                    margin:0.5rem;
                    font-size:0.8rem;
  
                    h3 {
                        font-size: 1.3rem;
                    }
  
                    .item_details_description_title {
                        font-size: 1rem;
                    }
                }
                .associated_card {
                    .associated_card_item {
                        margin: 0 0 1rem 0;
                        padding: 0;
                    }
                    .associated_card_title {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
  }
  
  @media (min-width: 481px) and (max-width: 768px) {
    .item_details {
      .item_details-card {
          margin-top: 1rem;
          max-width: 50rem;
          min-width: 30rem;
        }
    }
  }


@media only screen and (max-width: 1200px) {
    max-width: 100vw;
    margin-left: 1rem;
    margin-right: 1rem;
    overflow-y: auto;
    padding-top: 0;
    }

    //responsive mobile
    @media only screen and (max-width: 768px) {
        margin-left: 0;
        margin-right: 0;
        overflow: auto;
        padding: 0;
        .container-fluid {
            margin-top: 0.5rem;

            .table th, .table td {
                font-size: 0.7rem;
                padding-top: 0.2rem;
              }
        }
        h2 {
            margin-top:0.5rem;
            margin-bottom: 1rem;
            padding-bottom: 0.5rem;
            border-bottom: 1px solid #cccc;
            font-size: 1.2rem;
        }

        .item-page {
            flex-direction: column;
    
            .btn-group {
                margin-top: 0.5rem;
            }

            .btn-primary {
                margin-bottom: 0.5rem;
            }
        }

        .thumbnail_header_item {
            margin-bottom:3rem;
            .card-img {
                height: 30rem;
            }
        }
    }
}
