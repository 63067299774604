.img-overlay {
    width:100px;
}

.tooltip-inner {
    max-width: 320px;
    img {
        width:100%;
    }
}
