@import '../../../../../styles/colors';

.imgQuiz{ 
    background-attachment:fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 200vh;
    // width: auto;
}

.question {
    background-color: $background;
    border-radius: 10px;
    border:#ECA2FF solid 4px ;
    position: relative;
    top: 4%;
    left: 20%;
    width:60%; 
    height:92%;

    .textQuestion{
        color: aliceblue;
        position: relative;
        text-align: center;
        top: 8%;
        padding: 5%;
        margin-bottom: 4%;
        font-size:large;
    }

    .textAnswer{
        color: aliceblue;
        font-size:medium;
        text-align: center;
        position: relative;
        padding: 2%;
        margin-top:2%;

        .containerAnswer{
            position: relative;
            transform: translateY(5%);
        }

    }

    .previous{
        position: absolute;
        left: 1%;
        bottom: 5%;              
    }
    
    .next{
        position: absolute;
        float:right;
        bottom: 5%;
        right: 1%;
    }

    .containerValiderButton{
        text-align: center;
        transform: translateY(45%);

        .valider {
            margin: 10px;
    
        }
    }

    .containerPlusButton{
        text-align: center;
        transform: translateY(45%);
        
        .enSavoirPlus{
            margin: 10px;
        }
    }


    .feedBack{
        margin-top: 5%;
        
    }
    
    @media (min-width:100px) and (max-width:279px), (min-width: 281px) and (max-width:415px){
    
        .textQuestion{
            top: 4%;
            padding: 5%;
            margin-bottom: 2%;
            font-size:medium;
        }
    
        .textAnswer{
            font-size: small;
            padding: 1%;
        }
    
        .valider, .enSavoirPlus{
            margin-top: 3%;
        }
    }
    
    @media(width: 280px), (min-width: 415px) and (max-width: 500px){
    
        .textQuestion{
            top: 4%;
            padding: 5%;
            margin-bottom: 15%;
            font-size: small;
        }
    
        .textAnswer{
            transform: translateY(-10%);
            font-size: x-small;
            padding: 0.5%;
        }
    
        .valider, .enSavoirPlus{
            margin-top: 3%;
        }
    }
    
    
    @media (width: 540px), (width: 768px){
        .textQuestion{
            top: 4%;
            padding: 5%;
            font-size: medium;
        }
        
        .textAnswer{
            top: 20%;
            transform: translateY(-35%);
            font-size: small;
    
        }
        
        .valider, .enSavoirPlus{
            margin-top: 3%;
        }
    
    }
    
    @media (width: 820px){
        .textQuestion{
            top: 4%;
            padding: 5%;
            margin-bottom: 15%;
            
        }
    
        .valider, .enSavoirPlus{
            margin-top: 3%;
        }
    }
    
    @media (width: 912px){
        .valider, .enSavoirPlus{
            margin-top: 3%;
        }
    
        .textQuestion{
            top: 4%;
            padding: 5%;
            margin-bottom: 15%;
        }
    }
    
    @media(width: 1024px){
    
        .textQuestion{
            top: 4%;
            padding: 5%;
            font-size: small;
        }
    
        .textAnswer{
            transform: translateY(-11%);
            font-size: small;
        }
    
        .valider, .enSavoirPlus{
            margin-top: 3%;
        }
    }
}

.question {
    @media(width: 280px), (min-width: 320px) and (max-width: 425px), (width:820px){
        left: 0%;
        width: 100%;
        bottom: 5%;
    }
}