// Variables

// Screen size
$verysmall: 550px;
$medium: 900px;


// Container with the main information when several cards are aside

.col-maininfo {
    margin: 0 2em;

    @media screen and (max-width: $verysmall) {
        xs: 12;
    }
    @media screen and (max-width: 900px) {
        margin: 1em 1em;
    }

}

// Gives upper and lower space to a row

.row-midspace {
    margin-bottom: 2em;
    margin-top: 2em !important;
}

// All centered row

.row-allcenter {
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 4em 0;
}

// Gives margin to a col {

.col-midspace {
    @media screen and (max-width: 900px) {
        margin: 1em;
    }
}

.col-allcenter {
    text-align: center;
    display: flex;
    justify-content: center;
}


// Comments card

.display-comments {
    width: 50%;

    @media screen and (max-width: $medium) {
        width: 90%;
    }
}

// Informative cards

.warning-info {
    width: 50%;

    @media screen and (max-width: $medium) {
        width: 70%;
    }
}

.main-infos p {
    font-size: 16px;
    margin: 0;
}