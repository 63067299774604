@import '../../../styles/colors';

.result-list {
    width: 100%;
    padding: 0.5em;
    font-size: 1.15em;
    line-height: 1.3em;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    text-align: left;

    .custom-options {
        cursor: pointer;
    }
    .option-list-item {
        padding-left: 0.5em;
        padding-right: 0.5em;
    }

    .selectedLine-mult {
        background-color: $background;
        color: white;
    }
    
    .fixedLine {
        background-color: $background-grey;
        color: white;
    }

    &.disabled {
        background-color: $background-grey;

        .selectedLine-mult {
            background-color: $text-grey;
            color: white;
        }

        .custom-options {
            cursor: default;
        }
    }
}


