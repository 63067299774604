@import '../../../../../styles/colors.scss';

#interactions-tab-alone,
#interactions-tab-with-form {
    background-color: $background-grey;
    padding: 20px 30px;
    border-radius: 10px;
    min-width: 60em;
}
#interactions-tab-alone {
    margin: 0 20%;
    width: fit-content;
}
#interactions-tab-with-form {
    border-radius: 0 10px 10px 0;
    width: 55%;
}
#form-interaction {
    position: fixed;
    width: 45%;
    right: -1em;
    top: 6em;
}

@media only screen and (max-width: 1400px) {
    #interactions-tab-alone {
       margin: auto;
       width: auto!important;
    }
    #interactions-tab-with-form {
        border-radius: 10px;
        width: auto!important;
    }
    #form-interaction {
        position: fixed;
        z-index: 1;
        width: 80%;
        right: -1em;
        top: 2em;
    }
}