@import '../../styles/colors';

.item_detail_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    .card-title {
        font-size: 2.5rem;
        font-weight: bold;
        margin: 2rem 0 0;
        align-self: center;
        text-align: center;
        padding-top: 1rem;
        word-break: break-word;
    }

    .card-subtitle {
        font-size: 2rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        align-self: center;
        text-align: center;
    }
}

.item_detail_page {
    display: flex;
    justify-content: center;
}

.item_detail_page_contents {
    min-width: 50%;
    max-width: 50%;
}

.item_detail_page_rome_with_contents {
    min-width: 50%;
    max-width: 50%;
}

.item_detail_page_rome_without_contents {
    min-width: 100%;
    max-width: 100%;
}
.appellations {
    column-count: 3;
    margin-top: 0.5em;
}
.appellation {
    font-size: 14px;
}
@media screen and (max-width: 767px) {
    .item_detail_header {
        .card-title {
            font-size: 1.5rem;
        }

        .card-subtitle {
            font-size: 1rem;
        }
    }

    .item_detail_page {
        flex-direction: column-reverse;
    }

    .item_detail_page_contents {
        max-width: 100%;
    }

    .item_detail_page_rome_with_contents {
        max-width: 100%;
    }
    .appellations {
        column-count: 1;
    }
}

@media screen and (min-width: 768px) and (max-width: 1475px) {
    .item_detail_page {
        flex-direction: column-reverse;
    }

    .item_detail_page_contents {
        max-width: 100%;
    }

    .item_detail_page_rome_with_contents {
        max-width: 100%;
    }

}