.select-single-item-widget {
    display: flex;
    justify-content: center;
    gap: 2em;
    margin: 1em;
    width: 100%;
    border : 1px solid lightgrey;
    padding: 2em;
    border-radius: 5px;
    height: fit-content;
    position: relative;

    .selectedBadge {
       position: absolute;
       top: -2em;
       left: 1em;
    }

    .result-list-single {
        width: 100% !important;
        min-width: none !important;
        font-size: 1.15em;
        line-height: 1.2em;
        max-height: 15em;
        overflow-y: auto;
        margin: 0 !important;
    }
    
    .item-badge:not(.badge-fixed):hover {
        background-color: red !important;
        cursor: pointer;
    }
    
    .search-single-item {
        width: 100%;
        flex: 0 1 auto;
        height: fit-content;
        margin: 0 !important;
    }

}

.selectedLine {
    background-color: #021f3f !important;
    color: white !important;
}