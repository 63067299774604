img.max-width-200 {
    max-width: 200px !important;
    width: 100% !important;
}
//responsive mobile
@media (max-width: 768px) {
    img.max-width-200 {
        max-width: 88px !important;
        width: 88px !important;
        margin-top: 2rem;
    }
}

.subtitles-section {

    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 2rem 0 !important;
    gap: 1em;
    background-color: white;
    padding: 2em;
    border-radius: 5px;
    border: 1px solid #ced4da;

    .existing-subtitles {
        display: block;
        width: 100%;
    }

    .subtitle-card {
        width: 3em;
        padding: 0.75em 0;
        display: inline-block;
        background-color: white;
        border: 1px solid black;
        border-radius: 5px;
        margin: 0 0.5em;
        text-align: center;
    }

    select {
        margin-left: 1em;
    }

    .btn {
        width: fit-content;
    }

}
