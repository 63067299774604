@import '../../../styles/colors';

.media-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 14.8rem;
  margin: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 2rem;

  text-decoration: none;
  
  .card_body_with_infos {
    min-height: 13.6rem;
  }

  .card_body {
    padding: 0;
    max-width: 14.8rem;
    min-width: 14.8rem;

    .card_date {
      font-size: 0.7rem;
      color: $background;
      padding: 0.5rem 0.5rem 0 0.5rem;
      margin-bottom: 0.5rem;
      .category-logo {
        color : #ECA2FF !important;
      }
      .fa-cube {
        font-size: 1.1rem !important;
      }
      .fa-cubes {
        font-size: 1.1rem !important;
      }
    }

    .card-img {
      object-fit: cover;
      max-height: 9rem;
      min-height: auto;
      max-width: 14.8rem;
    }

    .card_title {
      font-size: 0.8rem;
      align-self: center;
      text-align: center;
      font-weight: 600;
      margin-bottom: 0.5rem;
      color: $background;
      padding: 0 1em;
    }

    .card_description {
      font-size: 13px;
      text-align: center;
      padding: 1.5em;
      padding-top: 0.5em;
    }
    .card_producers {
        font-size: 0.7rem;
        color: $text-grey;  
    }
  }

}

.media-border-radius-round {
    border-radius: 10px!important;
}

.media-card:hover {
  text-decoration: none;
  background-color: $pink;
  transform: scale(1.02);
  transition: 0.2s;
  cursor: pointer;

  .card_body {
    .card-img {
      filter: brightness(0.8);
    }
  }

  .card_description {
    color: $background;
  }
}

.media-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0.5rem 0.2rem;
  max-width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  .media-list-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $background;
    color: white;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0 0.3rem;
    height: 2.5rem;
    min-width: 15.5rem;
  }
}

.media-list-item:hover {
  background-color: $pink;
  transform: scale(1.02);
  transition: 0.2s;
  cursor: pointer;
}

.btn-pro-video {
    display: flex;
    justify-content: center;
    margin: -1.5em 1em auto 1em;

    .btn-pro-video-label, .btn-pro-video-name {
        margin: 0;
    }
    .btn-pro-video-label {
        color: black;
        font-size: 13px;
        font-weight: normal;
        margin-bottom: 0.5em;
    }
    .btn-pro-video-name {
        color: $background!important;
    }
}

//responsive mobile
@media (max-width: 768px) {
  .media-card {
    margin: 0 auto;
    margin-bottom: 2rem;

    .card_body {
      min-width: 14.8rem;

      .card_date {
        font-size: 0.6rem;
        padding: 0.3rem 0 0 0.3rem;
      }

      .card_title {
        font-size: 0.7rem;
      }
  
    }
  }

  .media-list {
    .media-list-item {
      min-width: 100%;
    }
  }
}
