// Max column value
$max-boxes: 20;

// Border
$border-max-width: 10;
$border-max-radius: 50;

// Font
$font-size-max: 100;

// Width
$width: 0 5 10 15 20 25 30 35 40 45 50 60 65 70 75 80 85 90 95 100;

// Margin and padding
$margin: 0 5 10 15 20 25 30 35 40 45 50 60 65 70 75 80 85 90 95 100 105 110 115 120 125 130 135 140 145 150 auto;
$padding: 0 5 10 15 20 25 30 35 40 45 50 60 65 70 75 80 85 90 95 100 105 110 115 120 125 130 135 140 145 150 auto;

// Breakpoints
$width-xs: 400px;  $width-sm: 768px;
$width-md: 992px;  $width-lg: 1200px;
$breakpoints: xs $width-xs sm $width-sm md $width-md lg $width-lg;

// Colors
$color-primary: #021f3f;
$color-text-primary: #54595F;
$color-text-secondary: #BCC8DE;
$color-hover: #114D89;
$color-background: #F7F7F7;
$color-border:#D680f9;
$color-placeholder: #BCC8DE;
$color-inactive: #BCC8DE;
$color-white: #FFFFFF;
$color-background-body: #EDF1F2;

// Notification colors
$color-success: #007E33;
$color-warning: #FF8800;
$color-info: #0099CC;
$color-danger: #CC0000;
$color-error: #CC0000;

$transition-time: 0.3s;
$rotation-deg: 45deg;

$colors:
        primary $color-primary
        text $color-text-primary
        secondary $color-text-secondary
        hover $color-hover
        'background' $color-background
        border $color-border
        placeholder $color-placeholder
        'white' $color-white
        inactive $color-inactive
        success $color-success
        error $color-error
        danger $color-danger
        info $color-info
        warning $color-warning;

:export {
        primaryColor : $color-primary;
        textPrimaryColor : $color-text-primary;
        textSecondaryColor : $color-text-secondary;
        colorHover : $color-hover;
        colorBackground : $color-background;
        colorBorder : $color-border;
        colorPlaceHolder : $color-placeholder;
        colorInactive : $color-inactive;
        colorWhite : $color-white;
        colorBackgroundBody : $color-background-body;
        colorSuccess : $color-success;
        colorWarning : $color-warning;
        colorInfo : $color-info;
        colorDanger : $color-danger;
        colorError : $color-error;
        }