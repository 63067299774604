table {
    text-align: center;
}
.td-quantity {
    display: flex;
    justify-content: center;
}
.select-product {
    max-width: 60em;
}
.select-quantity {
    max-width: 8em;
}