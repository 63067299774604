@import '../../../../../styles/colors.scss';

.action-icon {
    float:right;
    color:$pink;
    cursor: pointer;
    margin-top: auto;
}

.form-magicbox-link {
    border: 1px solid $background;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding: 1rem;

    .input-group {
        .input-group-text {
          font-size: 0.7rem;
        }
      }
    
    .custom-file-label {
    font-size: 0.75rem;
    }
}

//responsive mobile
@media (max-width: 768px) {
    .form-magicbox-link {
        padding: 0.5rem;

        .input-group {
            .input-group-text {
                font-size: 0.85rem;
                padding: 0.3rem;
                width: 100%;
            }
          }
    }
}