@import '../../styles/colors.scss';

@media all and (max-device-width: 480px) {
    .module-page-selector {
        flex-direction: column;
        .form-select {
            margin-top:0.5rem;
        }
    }
  }

   @media only screen and (max-width: 768px) {
    .module-page-selector {
        .form-select {
            margin:0.5rem 0;
        }
    }
  }