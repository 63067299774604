.controls {
    margin-top: 1.5em;
    display: flex;
    align-items: center;
        width: 100%;

    .clickable {
            font-size: 1.9em;
        }
.time-display {
    margin-right: 10px;
}

input {
    cursor: pointer;
}
}

.control-button {
    display: flex;
    align-items: center;
}
.time-range {
    flex-grow: 1;
    margin: 0 10px;
    height: 15px;
}