@import '../../../styles/colors.scss';

.card-comment {
    border-radius: 20px;
    width: 90%;
    margin-bottom: 2em;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 2em 2em 0 2em;
}

.author-comment {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.5em;
    word-break:break-all;
}

.date-comment  {
    font-size: 14px;
    font-weight: 400;
    color: $text-grey;
}

.footer-comment {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tab-comment-container {
    background-color: $background-grey;
    width: 100%;
    height: 60vh;
    padding: 2em !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.content-comment {
    font-family: Courier, monospace;
    line-height: 1.2;
    text-align: left;
    width: 100%;
}

.comment-form {
    margin-top: 2em;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.return-comment-btn {
    position: absolute;
    right: 1em;
}

.btn-delete-comment {
    width: 35px;
    height: 35px;
    border-radius: 35px;
    padding: 0.35em 0.6em;
    font-size: 16px;
    color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); 
    cursor: pointer;
    background-color: $red;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -2.5em;
    top: 0;

    &:hover {
        background-color: $background-grey;
        color: $red;
    }
}

#comments-scrollable-elem {
    width: 100%;
}

.search-add-block {
    width: 100%;
    margin-bottom: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    #inputGroupPrepend {
        flex: 1 1 auto;
        margin-right: 2em;
    }

    .btn-create-comment {
        flex: 0 1 auto;
    }
}