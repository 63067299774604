@import '../../../../styles/colors';

.image-uploader {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;

  img {
    max-height: 15rem;
    width: 90%;
    object-fit: fill;
    // object-fit: cover;?
    border-radius: 2%;
  }

  .btn-sm {
    width: 15rem;
    border-radius: 15px;
    font-size: 0.8rem;
    margin-top: 0.3rem;
  }
}

.form-upload-file {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .form-label {
    font-size: 0.8rem;
    font-weight: bold;
    color: $background;
    align-self: center;
    padding: 0 0.5rem;
  }

  #formFileInput {
    font-size: 0.9rem;
    background: $background;
    color: white;
    transition: all 0.15s ease;
    letter-spacing: 1px;
    cursor: pointer;
    line-height: 1.5;
    padding: 0.5rem 1rem;
    white-space: pre-wrap;
  }
}

@media only screen and (max-width: 962px) and (min-width: 501px) {
  .image-uploader {
    .btn-sm {
      font-size: 0.7rem;
    }
    img{
      max-height: 20rem;
      width: 100%;
    }
  }

  .form-upload-file {
    width: 100%;
    .form-label {
      font-size: 0.7rem;
    }
    #formFileInput {
      font-size: 0.7rem;
      line-height: 1;
      padding: 0.5rem 1rem;
    }
  }
}