@import '../../../../../styles/colors';

.fa-check{
    color: green;
    margin-left: 2%;
}

.fa-times{
    color: red;
    margin-left: 2%;
}

.answerButton{
    position: relative;
    left: 15%;
    margin-top: 10px;
    border: 2px solid rgba(#ECA2FF, 1);
    padding: 20px 12px;
    font-family: sans-serif;
    color: rgba(#fff, 0.8);
    cursor: pointer;
    transition: border-color 0.5s, background-color 0.5s, color 0.5s;
    width: 70%;
    border-radius: 5px;

    &:hover{

        background-color: #ECA2FF;
        border-color: transparent;
        color: $background;;
    }

}

.answerButton-active{
    position: relative;
    left: 15%;
    margin-top: 10px;
    padding: 20px 12px;
    font-family: sans-serif;
    color: $background;;
    background-color: #ECA2FF;
    cursor: pointer;
    transition: border-color 0.5s, background-color 0.5s, color 0.5s;
    width: 70%;
    border-radius: 5px;
}
