@import 'styles/colors';

@import "styles/boostrap-custom.css";
// @import "~bootstrap/scss/bootstrap";
@import "styles/icons.css";
@import "styles/variables";

// @import "mixins/mixins";
// @import "widgets/input";
// @import "widgets/button";
// @import "widgets/title";
// @import "widgets/notification";
// @import "widgets/loader";
// @import "animation";

// @import "skyflow/misc";
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700');

body {
  background-color: $color-background-body;
  min-height: 100vh;
  position: relative;
  color: $color-text-primary;
  // font: 18px/1.5; // invalid property value
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-family: Montserrat;
}

.container {
  max-width: none;
}

.align-right {
  float:right;
}

a {
  color:$background;
  text-decoration: underline;
}
a:hover {
  color:#666;
}

li.nav-item {
  margin:5px;
}


.row > * {
  flex-shrink: 1;
}

::placeholder {
    font-size: 1em;
}

.btn {
  border-radius: 5px;
}
/* primary */
.btn-primary, .btn-primary:focus {
  background-color: $pink;
  border-color: $pink;
}

.btn-primary:hover {
  background-color: $blue-medium;
  border-color: $blue-medium;
}

.btn-primary:not(:disabled):not(.disabled).active {
  background-color: $color-white;
  border-color:$pink;
  color: $text-grey;
  font-weight: 600;
  box-shadow: none;
}
.btn-group label {
    white-space: nowrap;
}
.btn-group > .btn:nth-child(2):not(.dropdown-toggle), .btn-group > .btn-group:nth-child(2) > .btn {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.btn-group > .btn:not(.dropdown-toggle), .btn-group > .btn-group > .btn {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.btn-group > .btn-primary {
  background-color: $color-white;
  color: $text-grey;
}

.btn-group > .btn-primary:hover {
  background-color: $blue-medium;
  color: $color-white;
}

.btn-check:checked+.btn-primary {
  background-color: $pink;
  border-color: $pink;
  color: $color-white;
}

 /* primary-reverse */
.btn-primary-reverse {
    background-color: white;
    border-color: white;
    font-weight: 600;
    color: $pink;
}
.btn-primary-reverse:hover {
    background-color: $pink;
    color: white;
}
.btn-primary-reverse:not(:disabled):not(.disabled).active {
    background-color: white;
    border-color:$pink;
    color: $color-white;
    box-shadow: none;
}

 /* secondary */
.btn-secondary {
  background-color: $text-grey;
  border-color: $text-grey;
}
.btn-info {
  color: #000;
}

.btn-secondary:hover {
  background-color: $color-karla-bold;
  border-color: $color-karla-bold;
}

.btn-secondary:not(:disabled):not(.disabled).active {
  background-color: $color-karla-bold;
  border-color:$color-karla-bold;
  color: $color-white;
  font-weight: 600;
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
  background-color: $color-karla-bold;
  border-color: $color-karla-bold;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

 /* success */
.btn-success {
  background-color: $green-medium;
  border-color: $green-light;
}

.btn-success:hover {
  background-color: $green-dark;
  border-color: $green-dark;
}

.btn-success:not(:disabled):not(.disabled).active {
  background-color: $green-dark;
  border-color:$green-dark;
  color: $color-white;
  font-weight: 600;
  box-shadow: none;
}

/* danger */
.background-red {
    background-color: $red-background;
}
.background-orange {
    background-color: $orange-background;
}
.btn-danger {
  background-color: $red;
  border-color: $red;
}

.btn-danger:hover {
  background-color: $color6bis;
  border-color: $color6bis;
}

.btn-danger:not(:disabled):not(.disabled).active {
  background-color: $color6bis;
  border-color:$color6bis;
  color: $color-white;
  font-weight: 600;
  box-shadow: none;
}

.btn:disabled, .nav-link:disabled {
  background: repeating-linear-gradient(
    -45deg,
    #fff,
    #fff 10px,
    $background-grey 10px,
    $background-grey 20px
  );
  color: $text-grey;
  cursor: not-allowed!important;
}
.btn-primary:disabled {
  border-color: $pink;
}
.btn-secondary:disabled {
  border-color: $text-grey;
}

/* global btn */

.btn-primary, .btn-secondary, .btn-success, .btn-danger, .btn-warning, .btn-info, .btn-light, .btn-dark, .btn-link {
  line-height: normal;
  box-shadow: 0px 4px 3px 0px rgba(0,0,0,0.15);
  transition: box-shadow 0.4s;
  height: fit-content;
}

.btn:hover {
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0);
  transition: all 0.5s;
}

.btn-primary, .btn-secondary, .btn-success, .btn-danger {
  color: $color-white;
}

.btn-primary, .btn-secondary, .btn-success, .btn-danger, .btn-warning, .btn-info {
  padding: 0.4em 2.7em;
  font-weight: 600;
}

// Customized button
.btn-link {
  background-color: transparent;
  border: none;
  color: #000;
  text-decoration: underline;
  padding: 0;
  font-weight: normal;
  box-shadow: unset;

  &:hover, &:focus {
    background-color: transparent;
    color: #000;
  }
}

.dropdown-item:hover {
  background-color: $pink;
}

.nav {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin:0;
  padding:0;
}

.nav-tabs {
  margin-top: 20px;
  margin-bottom: 20px;
}

.nav-tabs .nav-link {
	text-decoration: none;
  color: $color-white;
  font-weight: bold;
	background-color: $background;
	padding: 7px 25px;
	border-radius: 4px;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-link.active:focus, .nav-tabs .nav-link.active:hover {
  background-color: $pink;
  border-color: $pink;
  color: $background;
}

.nav-tabs .nav-link:hover {
  background-color: $blue-light;
  border-color: $blue-light;
  color: $color-white;
}

.table>:not(caption)>*>* {
  box-shadow: unset;
  background-color: unset;
}

//Accordion for create group form style

.accordion.create-group-accordion {
  .accordion-item {
    margin: 0;
    
    .accordion-header {
      .accordion-button {
        font-size: 1.5rem;
      }
    }
  }
}

.bubbleLogoAxonaut { // used to indicates if a contact or an element is linked to Axonaut's database
  background-color: $blue-axonaut;
  width: 25px;
  height: 25px; 
  border-radius: 20px; 
  margin-right: 0.5em;

  span {
    background: url("./images/axonaut_logo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    height: 25px;
    width: 25px;
    display: block;
  }
}


.bubbleLogoAxonaut-grey{
  background-color: $grey-axonaut;
}

// Style revision Alexandre R

.modal-content {
  overflow-x: auto;
}

.formSection { //Ajout de marges entre les différentes entrées des formulaires vidéos/groupes 
  margin: 1.5em 0;
}

.select-items-widget {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .search-group {
      width: 100%;
  }

  .select-block {
      width: 100%;
      margin: 0 !important;
  }
  
}

.tokens-section {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 0.6em;
}

.token-card {
  width: fit-content;
  padding: 0.5em;
}

.statusdiv {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 2em;
  text-align: left;

  i {
      margin-right: 0.8em;
  }
}

.vertical-align {
  display: inline-flex;
  align-items: center;
}

 /* light */
 .btn-light {
  border-color: $text-grey;
}

 /* light */
 .btn-lightdanger {
  background-color: $color6;
  border-color: $color6;
  color: white;
}


//react-datepicker

.react-datepicker-popper {
  z-index: 1000;
}

.react-datepicker__input-container {
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: white;
  margin-left: 0.5rem;
  font-size: 1rem;
  width: fit-content;

  input {
    border: none !important;
    background-color: transparent !important;
  }
}


@media only screen and (max-width: 1200px) {
  ::placeholder {
      font-size: 0.7em;
  }

  .card-body {
     overflow-x: scroll;
  }

  .nav {
    flex-wrap: wrap;
  }

}
//responsive mobile
@media only screen and (max-width: 768px) {
  .btn-primary, .btn-secondary, .btn-success, .btn-danger {
    font-size: 0.7rem;
    padding: 5px 10px;
  }

  .nav-tabs .nav-link {
    font-size: 0.7rem;
    padding: 5px 10px;
  }

  .input-group {
    .input-group-text {
      font-size: 0.7rem;
      height: 1.9rem;
      width: 100%;
    }
    .form-control {
      font-size: 0.8rem;
      height: 1.9rem;
    }
  }

  .form-check-label {
    font-size: 0.7rem;
  }

  .form-label {
    font-size: 0.7rem;
  }

  .custom-file-label {
    font-size: 0.7rem;
  }

  .custom-file-label::after {
    font-size: 0.7rem;
  }
}

.header-action {
  display: flex;
  justify-content: right;
  padding: 0;
}

// Shadow
.shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); 
}

// Handle cols 3 and justify-content
.m360-col3-start,
.m360-col3-center,
.m360-col3-end  {
    display: flex;
    align-items: center;
}
.m360-col3-center  {
    justify-content: center;
}
.m360-col3-end  {
    justify-content: end !important;
}
@media only screen and (max-width: 1200px) {
    .m360-col3-start,
    .m360-col3-center,
    .m360-col3-end  {
        justify-content: start;
    }
    .m360-col3-start,
    .m360-col3-center {
        margin-bottom: 0.5em;
    }
}
@media only screen and (max-width: 767px) {
    .m360-col3-start,
    .m360-col3-center,
    .m360-col3-end  {
        margin-bottom: 1em;
    }
}

// Cursors
.clickable, .clickable input, .clickable label {
  cursor: pointer;
}
.cursor-default {
  cursor: default!important;
}

.form-check-label, .form-check-input {
  cursor: pointer;
}
@media (max-width: 1000px) {
  .modal-dialog {
    margin: 0.5rem;
  }
}
@media (max-width: 1000px) {
  #modal-interactions {
    max-width: none!important;
  }
}

#info-clientCRM-modal {
  min-width: 85em;
  width: auto;
}

// Recherche
.search-clear-icon {
  font-size: 20px;
  margin-left: 0.5em;
  margin-bottom: 0em;
  cursor: pointer;
  color: #000;
  font-weight: 600;
}

.break-word {
  word-wrap: break-word;
}

// Tab title
.title-tab {
  font-size: 18px;
  font-weight: 600;
}
.shadow-element {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); 
}

// Word break all
.word-break-all {
  word-break: break-all;
}
// Label select input
.label-select {
  font-weight: bold;
}
// Error input
.error-input {
  border: 1px solid $red;
  border-radius: 5px;
}
// Modal create multi users/contacts
#client-create-multi-user-modal, #client-create-multi-tag-ope-modal {
  min-width: 80%;
}

textarea{
  white-space:pre-wrap;
}

// Choices selector inline
.choices-selector-inline {
  display: flex;
  border: 1px solid #eca2ff;
  border-radius: 20px;

  div {
    padding: 0.2em 1em;
    cursor: pointer;
    border: 1px solid #eca2ff;
  }
  div:hover {
    font-weight: bold;
  }
  div:first-child {
    border-radius: 20px 0 0 20px;
  }
  div:last-child {
    border-radius: 0 20px 20px 0;
  }

  div.selected {
    background-color: #eca2ff;
    font-weight: bold;
  }
  white-space:pre-wrap;
}
.d-none {
  display: none;
}

// Interaction window
.form-interaction-full-width {
  position: fixed;
  z-index: 1;
  right: -1em;
  top: 8em;
  width: 40%;
}

@media only screen and (max-width: 1400px) {
  .form-interaction-full-width {
      width: 80%;
      top: 2em;
  }
}
@media only screen and (max-height: 800px) {
  .form-interaction-full-width {
      top: auto;
      bottom: 4em;
  }
}

// Modal
.modal {
  background-color: rgba(0, 0, 0, 0.25);
}
#modal-interaction {
  .modal-body {
    margin-top: 2em;

  }
  .card-interaction {
    margin-bottom: 0;
  }
  .modal-content {
    background-color: transparent;
    border: none;
    min-width: fit-content;
  }
  .modal-footer, .modal-header {
    display: none;
  }
}

.display-linebreak {
  white-space: pre-line;
}

.tooltip-delete-client .tooltip-inner {
  max-width: none;
  width: 30em;
}

div:has(> #btn-pdf-videos-by-group) {
    padding-left: 0;
    width: auto;
}
tr.active {
    font-weight: bold;
    background-color: #71baff !important;
}
.link-pull-down-button {
    margin-top: 1.5em;
    font-size: 14px;
    background-color: transparent;
    text-decoration: underline;
    border: none;
    width: auto;
}
.badge-code {
    background-color: $blue-medium;
    width: fit-content;
    color: white;
    padding: 0.5em 1em;
    border-radius: 30px;
    font-weight: bold;
    margin-left: 0.5em;
}

.expand-col {
    z-index: 2;
    width: 65vw !important;
    position: absolute;
    right: 2em;
}
.playlist-professional-card {
    margin: 2em 0;
}
@media screen and (max-width: 768px) {
    .expand-col {
        position: relative;
        width: 100%!important;
    }
    .btn-options-table {
        display: none;
    }

    h5 {
        font-size: 16px;
    }
    .check-sort {
        flex-direction: column;
    }
}

@media screen and (max-width: 1475px) {
    .col-5, .col-7 {
        width: 100%!important;
    }
}

.crop-text-with-dot {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.w-fit-content {
    width: fit-content!important;
}