@import '../../../styles/colors';

.tooltip-info {
    .info-icon {
        font-size: 18px;
        color: $text-grey;
    }

}
.tooltip-description .tooltip-inner {
    max-width: 85vw;
    margin-left: 250px;
    background-color: $blue-light;
    color: black;
    padding: 0.5em 1em;
}