#tab-actions {
    min-height: auto;
}
.tab-btn-row {
    padding: 0.5em 2em;
}
#row-edit-btns div {
    width: auto;
    margin-bottom: 1em;
}
#edit-multiple-sub-end-date-modal {
    min-width: 80em;
}