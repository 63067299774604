.quiz-answers-table {
    .show-color-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .show-color {
            width:30%;
            height: 15px;
            border-radius: 5px;
            border: 1px solid black;
        }
    }
}