.playlist_header {
    display: flex;
    flex-direction: column;

    .playlist_header_details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 1.5rem;
        padding: 1rem;
        width: 100%;
    }

    .playlist_header_btn_actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 1.5rem;

        .btn-primary:nth-child(1) {
            background-color: red;
            font-size: 0.8rem;
        }

    }
}