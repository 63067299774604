
.faq-title{
    text-align: center;
}

.ContactRedirection.btn.btn-primary{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.titleFAQ{
    text-align: center;
    
}

.row-faq{

        .fa-play-circle{
            margin-bottom: 1rem;
        }
        
        .content{
            position: relative;
            
        }
        .text{
            z-index: 1;
            position: absolute;
            text-align: center;
            left: 0;
            right: 0;
            top: 20%;
            color: white;   
        }
        
        img{
            height: 25vh;
            width: 15vw;
            border-radius: 20px;
            filter: brightness(90%);
            border: none;
        }
        
        .align-self-stretch.card{
            border: none;
            margin-left: 1rem;
            margin-top: 1rem;
            text-align: center;
        }
}

.ParagrapheFAQ{
    text-align: center;
}

.ErrorMessage{
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 1.2rem;
    margin-top: 2rem;
}

.contactRedirect{
    text-align: center;
    
}

.controller{
    width: 20rem;
}

.inside-headset{
    width: 20rem;
}

.faq-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:1rem;

    .searchBar{
        flex:2;
    }
    .buttonGroup {
        flex:3;
        display: flex;
        justify-content: space-between;
        align-items:center;

     .choiceButton{
        align-items: center;
    }
     }
}

//responsive mobile

//***Responsive***//


@media (max-width: 1000px) {
    .faq-action {
        flex-direction: column;
        justify-content: center;
        
        .buttonGroup{
            flex-wrap: wrap;
            max-width: 1000px;
            margin-top: 1rem;
        }

        .btn-primary {
            margin-bottom: 1rem;
        }
    }
}
