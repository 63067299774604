@import '../../../styles/colors.scss';

.select-multiple {
    height: 1.8em;
    width: 100%;
    background-color: $background-grey;
    border: 1px solid $text-grey;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 0.8em;
    color: #000;
    cursor: pointer;

    i {
        font-size: 10px;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0.17);
    }
}
.container-options {
    position: relative;
}
.select-options-multiple {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    background-color: #fff;
    border: 1px solid $text-grey;
    border-radius: 5px;
    right: 0;
    left: 0;
    max-height: 200px;
    overflow: auto;
    padding-top: 0.5em;
}
.select-option {
    cursor: pointer;
    padding: 0.2em 0.8em;

    &:hover {
        background-color: rgba(0, 0, 0, 0.17);
    }
    i {
        font-size: 15px;
    }
    .fa-minus-square {
        color: $red;
    }
    .fa-check-square {
        color: $green-dark;
    }
}
.label-option {
    margin-bottom: 0;
    position: absolute;
    left: 2.5em;
    right: 0;
    cursor: pointer;
}
.uncheck-all {
    color: $text-grey;
}
.icon-uncheck-all {
    margin: 0;
    cursor: pointer;
    font-size: 15px;
}
.close-select-outside {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}