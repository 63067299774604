@import '../../styles/variables';
@import '../../styles/mixins/mixins';


._metiers360-loader-widget {
  margin: 40px auto;
  width: 80px;
  text-align: center;

  .child {
    width: 20px;
    height: 20px;
    margin-top: 3rem;
    background-color: $color-placeholder;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
    animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  }

  &._metiers360-loader-primary-widget {
    .child {
      background-color: $color-primary;
    }
  }

  .child-1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .child-2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-three-bounce {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes sk-three-bounce {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

}




