@import '../../../styles/colors';

.item_details {
    .item_details-card {
        .item_details-body {
            .containerButton{
                width:100%;
                text-align: center;
                padding: 1rem;
            }
            .quiz_presentation {
                width: 100%;
                display: flex;
                justify-content: center;
                flex-direction: row;
                margin: 0;
                padding: 0;

                .quiz_presentation_elem {
                    max-width: 23rem;
                    min-width: 23rem;
                    margin: 0.4rem;
                    background-color: $background;
                   
                    .card-header {
                        color: #fff;
                        font-size: 1.5rem;
                        font-weight: bold;
                        padding:0.7rem 1rem;
                    }

                    .card-body {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;


                    .intro-text-section, .outro-text-section {
                        font-size: 1rem;
                        text-align: center;
                        padding: 0.5rem;
                        color: white;
                        .null-content {
                            font-size: 1rem;
                            text-align: center;
                        }
                    }

                    .intro-background,
                    .outro-background {
                        padding: 0.5rem;

                        img {
                            width: 100%;
                            height: auto;    
                        }

                        .null-content {
                            font-size: 1rem;
                            text-align: center;
                        }
                    }
                }
            }
        }

            .quiz_questions {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                margin-top: 1rem;
                padding: 0;
                box-shadow: 0 0.8rem 1rem rgba(0, 0, 0, 0.4);
                border-top-width: thin;
                border-top-style: solid;
                border-top-color: #e5e5e5;
                overflow: auto;

                .quiz_questions_title {
                    font-size: 1.5rem;
                    font-weight: bold;
                    margin: 2rem 0 0.5rem 1rem;
                    padding: 0.5rem;
                }
            }
        }
    }
}

.show-color-container {
    .show-color {
        padding: 0.5rem 1rem;
        border-radius: 15px;
        border: 1px solid black;
        width: 100%;
        margin: 0 auto;
    }
}

//responsive mobile
@media all and (max-device-width: 480px) {
    .item_details {
        .item_details-card {
            .item_details-body {
                .quiz_presentation {
                    flex-direction: column;
                    .quiz_presentation_elem {
                        padding: 0.5rem;
                        margin: 0.3rem;
                        max-width: 20rem;
                        min-width: 18rem;
                        
                        .card-header {
                            font-size: 0.8rem;
                        }

                        .card-body {
                            .intro-text-section, .outro-text-section {
                                font-size: 0.7rem;
                                .null-content {
                                    font-size: 0.7rem;
                                }
                            }
                            .intro-background, .outro-background {                         
                                    font-size: 0.7rem;
                                    .null-content {
                                        font-size: 0.7rem;
                                }
                            }
                        }
                    }
                }
               
                .quiz_questions {
                        .quiz_questions_title {
                        font-size: 1rem;
                        margin: 1rem 0 0.5rem 0.5rem;
                        padding: 0.5rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .item_details {
        .item_details-card {
            max-width: 30rem;
            min-width: 20rem;
            .item_details-body {
                max-width: 30rem;
                .quiz_presentation {
                    flex-direction: column;
                    .quiz_presentation_elem {
                        padding: 0.5rem;
                        margin: 0.3rem;
                        max-width: 30rem;
                    }
                }
            }
        }
    }
}