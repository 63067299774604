@import '../../../styles/colors.scss';

#interaction-form-template {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 10px 0 0 10px;
}
#required-text {
    font-size: 13px;
    font-weight: 400;
}
.input-form-interaction {
    display: flex;
    align-items: center;
    margin-bottom: 2em;
}
.action-interaction {
    color: #fff!important;
}
.no-clients {
    color: $text-grey;
    font-style: italic;
    margin-bottom: 0;
}