#scroll-to-top {
    position: fixed !important;
    bottom: 0 !important;
    right:0 !important;
    transform: translate(-2.5rem, -2.5rem) !important;
    z-index: 1000 !important;
    opacity: 0.6;
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.3);
    width: auto;

    span {
        display: none;
    }

    .fa-caret-square-up {
        font-size: 1.2rem;
        box-shadow: inset 0px 0px 0px 2px rgba(0, 0, 0, 0.2);
    }
    
}

#scroll-to-top:hover {
    opacity:1;
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.3);
    span {
        display: unset;
        font-size: 0.8rem;
    }
    
}