.rome_detail_view {
    .footer {
        margin:0.5rem;
        font-size:0.8rem;
        font-style: italic;
        text-align: right;
    }
}
.content-text {
    background-color: transparent!important;
}
.rome-domain-links {
    align-items: center;
}
@media screen and (max-width: 767px) {
    .rome-domain-links {
        flex-direction: column;
        align-items: flex-start;
    }
}