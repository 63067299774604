.draggable-list-group {
    .alert {
        max-height: 900px;
        overflow: auto;
        margin-top: 0.5rem
    }
    .img-thumbnail {
        width: 150px;
    }
    
}