@import "../../styles/colors";

.stats-container {

  .stats-container-description {
    opacity: 0.6;
    font-size: 1.2rem;
  }

  .no-data p{
    color: grey;
    font-weight: bold;
  }

  .btn {
    margin-bottom: 15px;
  }

  h3 {
    text-align: center;
    color: $background;
  }

  #videos-played-chart {
    margin-bottom: 15px;
  }

}
