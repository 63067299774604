.tox {
    z-index: 0;
}

.react-datepicker__tab-loop {
    position: absolute;
    top: 0;
}

.textLimit {
    font-size: 0.9em;
    padding: 0.1em 0.3em;
}