@import '../../../styles/colors.scss';

.toasts-wrapper {
    position: fixed;
    top: 1em;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 200000;
    width: 600px !important;
    max-width: none !important;
}
.toast {
    max-width: none!important;
    width: 100%;
    font-weight: bold;
    cursor: default;
}
.color-white, .close-toast {
    color: #fff!important;
}
.close-toast {
    cursor: pointer;
}