@import '../../../styles/colors';

.select-single-item-widget {
    margin: 0;
    padding-left: 0!important;
    padding-right: 0;
    padding-bottom: 0;
    border: transparent;

    .dropdown {
        width: 95%!important;
    }
}