// .imgQuiz{
//     height: 80vh;
//     align-items: center;
//     background-size: 100%;
// }

// @media (width: 820px){
//     .imgQuiz{
//         height: 85vh;
//         width:100%;
//         align-items: center;
//         z-index: 1;
//     }
// }

// @media (width: 912px){
//     .imgQuiz{
//         height: 85vh;
//         width:100%;
//         align-items: center;
//         z-index: 1;
//     }
// }
    
.no-quiz-question {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
}