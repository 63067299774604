@import '../../../../../styles/colors.scss';

.form-customize-webapp {
    border: 1px solid $background;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding: 1.5rem;

    .input-group {
        margin-top: 1rem;
    }

    .input-group > .input-group-prepend > .input-group-text {
        font-size: 0.8rem;
        height: 3rem;
    }

    .custom-file-label {
        height: 3rem;
        p {
            font-size: 0.8rem;
            margin: 0;
            padding: 0;
        }
        span {
            font-size: 0.7rem;
        }
    }

    .custom-file-label::after {
        height: 2.9rem;
    }
}

//responsive mobile
@media (max-width: 768px) {
    .form-customize-webapp {
        padding: 0.5rem;

        .form-label {
            font-size: 0.8rem;
        }

        .custom-file-label {
            padding: 0.2rem;
        }

        .custom-file-label p{
            font-size: 0.6rem;
            width: 100%;
        }

        .custom-file-label span{
            font-size: 0.44rem;
         }

         .custom-file-label::after {
            font-size: 0.575rem;
            padding: 0.2rem;
        }

        .input-group > .input-group-prepend > .input-group-text {
            font-size: 0.575rem;
            padding: 0.2rem;
        }
    }
}