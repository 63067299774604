.accordion{

    .accordion-button.collapsed{
        color: #00204a;
    }

    .accordion-button.collapsed:hover{

        .fa-plus-circle{
            width: 35px;
            color: #4DABFF;
        }
    }

    .accordion-button.collapsed::after{
        opacity: 0;
    }

    .accordion-button::after{
        opacity: 0;
    }

    .accordionItem.accordion-item{
        // background-color: white;
        border: none;
        margin-bottom: 2rem;
        border-radius: 50px;
        
    }

    .accordion-item{
        // background-color: #dce2e3;
    }

    .accordionBody{
        margin-top: -1rem;
    }

}

.sectionTitle{
    text-align: center;
    margin-bottom : 4rem;
    font-size: medium;
    font-weight: bold;
    transform: translate(0,20px);
    position: relative;
    font-size: 20px;
}

.fa-plus-circle{
    width: 35px;
    color: #EEA0FF;
}

.fa-minus-circle{
        width: 35px;

}
