.backdrop {
    position: fixed;
    width:100vw;
    height:100vh;
    background: #333;
    opacity: .6;
    z-index:12;
    overflow: hidden;
    top: 0; 
    right: 0; 
    bottom: 0; 
    left: 0;
}

