.file-rows-container{
    width: 50% !important;
    margin: auto !important;
}


.cursor-pointer {
    cursor: pointer;
}

.delete-new-file-row, .delete-file {
    color: red;
}



@media (max-width: 1300px) {
    .file-rows-container{
        width: 100% !important;
    }
}
