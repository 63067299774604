.addresses-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}


.address-card {
    padding: 3em;
    margin: 1em;
    background-color: white;
    border-radius: 10px;
    width: 25em;
    height: fit-content;
}