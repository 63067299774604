@import '../../../../styles/colors.scss';

.main-infos p {
    font-size: 16px;
    margin: 0;
}
#loader-subscription-return-order ._metiers360-loader-widget{
    margin: 0;
    div {
        margin: 0;
    }
}
.subscription-dates {
    color: $text-grey;
}