@import "../../styles/colors.scss";

.activities-card {
  margin:0 auto;
  padding:0;
}
 
.activities-data {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $pink;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  margin: -3.2rem auto 0;
  font-size: 1.1rem;
  color: #fff;
  font-weight: bold;
}

.activities-name {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(65, 62, 62);
  font-size: 0.8rem;
  margin: 1rem auto 0;
  padding: 0;
  font-weight: bold;
}


@media only screen and (max-width: 1255px) {
  .activities-data {
      font-size: 0.8rem;
      height: 4.5rem;
      width: 4.5rem;
      margin: -3rem auto 0;
  }
  
  .activities-name {
      color: rgb(65, 62, 62);
      font-size: 0.7rem;
  }
}

  @media only screen and (max-width: 962px) and (min-width: 501px) {
  .activities-data {
      font-size: 1.2rem;
      height: 6rem;
      width: 6rem;
      margin: -3.4rem auto 0;
  }
    
  .activities-name {
      color: rgb(65, 62, 62);
      font-size: 1.1rem;
  }
}

@media only screen and (max-width: 500px) and (min-width: 320px) {
  .activities-data {
      font-size: 0.6rem;
      height: 3rem;
      width: 3rem;
      margin: -2.8rem auto 0;
  }
    
  .activities-name {
      color: rgb(65, 62, 62);
      font-size: 0.7rem;
  }
}
  