@import '../../styles/colors.scss';

.welcome-stats {
    display: flex;
    justify-content:space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 1rem;
    // margin:3rem 0 0 0; 
}

.numbers-banner {
    width: 80%;
    padding: 0 0 2em 0 !important;
    margin-bottom: 3em;
}
.welcome-card {
    width:32%;
    border:solid 1px $blue-medium;
    box-shadow: 8px 5px 5px $background;
    // margin:0;
    // padding:0;

    h1 {
        background-color: linear-gradient($blue-light, $background);
        background-color: $blue-medium;
        text-align: center;
        padding-top: 3rem;
        padding-bottom: 4rem;
        font-size:1.3rem;
        text-transform: uppercase;
        color:white
    }
} 

@media only screen and (max-width: 962px) and (min-width: 501px) {
    .welcome-card {
        width: 100%;
        margin: 1rem;

        h1 {
            padding-bottom: 4rem;
        }
    }
}

@media only screen and (max-width: 500px) and (min-width: 320px) {
    .welcome-stats {
        margin:2rem 0 0 0; 
    }
    .welcome-card {
        width: 100%;
        margin: 0.5rem;

        h1 {
            font-size: 1rem;
            padding-bottom: 3rem;
        }
    }
}