@import '../../styles/colors';

.playlist_detail {
        h3 {
            display:inline;
        }
        .card {
            margin-bottom: 1em;
        }

    .badge {
        padding:5px;
        cursor:pointer;
    }

    .playlist_select_items {
        margin-top: 1em;
        margin-bottom: 1em;
        width: 50%;
        max-height: 900px;
        overflow: auto;
      

        .card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1em;
            width: 100%;
        }

        .card-group {
            display: flex;
            justify-content: center;
            align-items: top;
            width: 100%;
            margin-bottom: 1rem;
        }
    }
    .btn-group label {
        margin: 0;
    }
    .btn-group .disabled {
        background: repeating-linear-gradient(
            -45deg,
            #fff,
            #fff 10px,
            $background-grey 10px,
            $background-grey 20px
        );
        border: none;
    }
    .btn-group .btn {
        border: none;
    }
    .btn-check:checked + .btn-primary {
            color: #000!important;
    }
    .btn-check:checked + .disabled {
        color: #000;
        border: $pink 2px solid;
        background: repeating-linear-gradient(
            -45deg,
            $pink,
            $pink 10px,
            $background-grey 10px,
            $background-grey 20px
        );            
    }
}
//responsive mobile
@media (max-width: 768px) {
    .playlist_detail {
        strong {
           font-size: 0.8rem;
        }

        .badge {
            font-size: 0.8rem;
            margin: 1rem;
        }
        
        .playlist_select_items {
            width: 100%;
        }
    }
}