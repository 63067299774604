@import '../../styles/colors.scss';

.card-entity {
    width: 25em;
    min-width: 25em;
    max-width: 25em;
    height: 17em;
    border-radius: 10px;
    padding: 0;
    margin: 1.5em 0.8em;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow:
       4px 4px 0 $background-grey,
     -1px -1px 0 $background-grey,  
      1px -1px 0 $background-grey,
      -1px 1px 0 $background-grey,
       1px 1px 0 $background-grey;
    border: none;
    overflow: hidden;
    cursor: pointer;

    &:hover {
        .card-entity-content {
            opacity: 0;
        }
    }
}
.card-entity-main {
    background: linear-gradient(to bottom left, $pink, $blue-medium);
}
.card-entity-secondary {
    background : linear-gradient(to top right, $pink, $blue-medium);

    .icon-entity {
        display: flex;
        justify-content: end;
        margin-top: -0.5em;
        font-size: 23px;
        color: white;
        margin-right: 1em;
    }
}
.card-entity-content {
    width: 100%;
    margin-top: 3.5em;
}
.card-entity-content-hover {
    background: $pink;
    transition: transform 0.5s ease-in-out;
    transform: translateY(100%);
    opacity: 0;
    margin-top: -14em;
    padding: 2em 1em;
    min-height: 19em;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.card-entity:hover .card-entity-content-hover {
    transform: translateY(0);
    opacity: 1;
}

.card-entity-number {
    font-size: 70px;
    font-weight: bold;
    margin: 0;
    color: white;
    text-shadow: 3.5px 3px 0 $pink;

    ._metiers360-loader-widget {
        margin-top: 0;
        margin-bottom: 0;
    }
}
.card-entity-title {
    font-size: 30px;
    font-weight: 600;
    color: white;
}
#list-home-romes {
    list-style : none;
}
#indent-1 {
    margin-left: 2em;
}
#indent-2 {
    margin-left: 4em;
}
#indent-3 {
    margin-left: 6em;
}

@media screen and (max-width: 767px) {
    #info-romes {
        display: none;
    }
}
    