.loader-lists {
    width: auto;

    ._metiers360-loader-widget {
        width: auto;
        margin: 0;

        .child {
            margin: 0;
        }
    }
}
#btnFilters {
    width: auto!important;
}

.react-datepicker-wrapper {
    max-width: 60% !important;
  
    input {
        width: 100%;
    }
  }
  