@import '../../styles/colors.scss';

.video-graphs {
    display: flex;
    flex-wrap: wrap;
    margin: 1em;
    gap: 1em;
}

.stat-graph {
    width: 45% !important;
}

.stats-select-clients {
    border: solid 2px $background;
    border-radius: 8px;
    z-index: 1;
    position: relative;
    background: white;

    .contents_details_body {
        margin-bottom: 2em;
        overflow: hidden;

        .card_group_media {
            max-height: 58vh;
            overflow-y: auto;
        }
    }
}

.expand-btn {
    z-index: 2;
    position: sticky;
    display: flex;
    width: 100%;

    i {
        width: 2.5em;
        height: 2.5em;
        display: flex;
        background: white;
        border-radius: 2em;
        text-align: center;
        margin: auto;
        margin-top: -1em;
        padding: 0.8em;
        border: solid 2px;
    }
}