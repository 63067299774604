.text-lab {
    font-size: 16px;
}
.text-little-lab {
    font-size: 13px;
}
.lab-image {
    max-width: inherit;
    height: auto;
    padding: 1em;
}
.col-img-lab {
    display: flex;
    justify-content: end;
}
.content-img {
    width: 60%;
}
.lab-end-card {
    border: solid 1px black;
    padding: 1em;
    width: 60%;
}
@media (max-width: 1400px) {
    .col-img-lab {
        justify-content: center;
        margin-bottom: 5em;
    }
    .lab-end-card, .content-img {
        width: 100%;
    }
}