@import '../../../styles/colors';

#btnFilters {
    background-color: $background-grey;
    color: black;
    display: flex;
    width: 8em;
    justify-content: space-between;
    padding: 1em;
    align-items: center;
    border-radius: 5px;
    border-color: transparent;

    p {
        margin: 0;
        font-weight: 600;
    }
    &:hover {
        color: $text-grey;
    }
}
#btnInitFilters {
    padding: 0.4em 0.5em;
    margin-left: 1em;

    i {
        font-size: 13px;
    }
}
#filtersZone {
    padding-top: 2em;
    padding-bottom: 1em;
}

.hide-filters {
  visibility: hidden;
  height: 0;
  padding: 0!important;
}
.filters-loader {
    position: absolute;
    left: 25%;
    top: 10%;
}
#end-subscription-label {
    min-width: 10.5em;
}
.form-check-label, .label-select {
    font-weight: bold;
    font-size: 14px!important;
    margin-bottom: 0;
    margin-right: 0.5em;
}
#loader-first-loading-filter ._metiers360-loader-widget{
    margin: 0 20em;

    div {
        margin: 0;
    }
}

.searchBlock {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.5em;
    .input-group-text {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
    }
    .contactSearch {
        width: 2em;
        height: 2em;
        flex-shrink: 0;
        padding: 0 !important;
        margin-left: auto;
    }
}

@media (max-width: 1400px) {
    .m360-col3-end {
        padding-top: 0.5em;
        justify-content: start!important;
    }
}