@import '../../../../../styles/colors.scss';

.form-webapp-menu {

  .webapp-menu-information {

    .webapp-menu-name {
      font-size: 1rem;
      font-weight: bold;
      color: $pink;
      margin-right: 30px;
    }

  }

  .webapp-menu-items-row {

    margin-top: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .webapp-menu-items-row-check {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

  }
  border: 1px solid $background;
  padding: 1.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

//responsive mobile
@media (max-width: 768px) {
  .form-webapp-menu {
    padding: 0.5rem;
    .webapp-menu-items-row {
      font-size: 0.8rem;

      .webapp-menu-items-row-check {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}